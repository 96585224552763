import React from 'react';
import './Footer.css';
import fbIkona from '../../assets/icons/fb.png';
import instaIkona from '../../assets/icons/instagram.png';
import mailIkona from '../../assets/icons/gmail.png';
import ytIkona from '../../assets/icons/youtube.png';
import vimeoIkona from '../../assets/icons/vimeo.png';



const Footer = () => {
  return (
<div className='footer-padding-top'>
<hr className="crta"/>
    <div className='stef__footer'>
      
      <div className='stef__footer-links'>
        <div className='stef_footer-links_item'>
          <a className='link-mail-telefon' href='mailto:info@janstefancic.com'>info@janstefancic.com</a>
        </div>
        <div className='footer-ikone'>
          <div className='stef_footer-links_item'>
            <p><a className='social' href="https://vimeo.com/user24654712" target="_blank" rel="noopener noreferrer"><img className='ikona' src={vimeoIkona} alt="Vimeo" /></a></p>
          </div>
          <div className='stef_footer-links_item'>
            <p><a className='social' href="https://www.youtube.com/@janstefancic1671/featured" target="_blank" rel="noopener noreferrer"><img className='ikona' src={ytIkona} alt="YouTube" /></a></p>
          </div>
          <div className='stef_footer-links_item'>
            <p><a className='social' href='mailto:info@janstefancic.com' target="_blank" rel="noopener noreferrer"><img className='ikona' src={mailIkona} alt="Mail" /></a></p>
          </div>
          <div className='stef_footer-links_item'>
            <p><a className='social' href="https://www.facebook.com/jan.stefancic.7" target="_blank" rel="noopener noreferrer"><img className='ikona' src={fbIkona} alt="Facebook" /></a></p>
          </div>
          <div className='stef_footer-links_item'>
            <p><a className='social' href="https://www.instagram.com/janstefancic/" target="_blank" rel="noopener noreferrer"><img className='ikona' src={instaIkona} alt="Instagram" /></a></p>
          </div>
        </div>
        {/* <div className='stef_footer-links_item'>
          <a className='link-mail-telefon'href='tel:+38640381313'>+386 40 381 313</a>
        </div>  */}
      </div>
    </div>
    </div>
  )
}

export default Footer